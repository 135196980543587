@import '../../styles/helpers/index.scss';

.message {
  @include align-center;
  justify-content: center;
  flex-direction: column;

  height: inherit;

  h1 {
    font-size: 42px;
    font-weight: 600;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }

  a {
    margin: 30px 0 0;
    font-size: 42px;
    text-decoration: none;
    color: map-get($colors, 'text.primary');
  }
}
