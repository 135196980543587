$colors: (
  'background.default': #121212,
  'background.paper': #121212,
  'text.primary': #fff,
  'text.secondary': #ffffffb3,
  'text.disabled': #ffffff80,
  'action.active': #fff,
  'action.hover': #ffffff14,
  'action.selected': #ffffff04,
  'action.disabled': #ffffff4d,
  'action.disabledBackground': #ffffff1f,
);

$desktop-md: 1440px;
$desktop-sm: 1200px;
$tablet-lg: 1024px;
$tablet-md: 900px;
$tablet-sm: 768px;
