@import '../../styles/helpers/index.scss';

.future-games {
  h2 {
    padding: 0 15px;
    text-align: right;
    margin: 0 0 15px;
  }
}

.game {
  &-list {
  }

  &-card {
    text-decoration: none;
    color: map-get($colors, 'text.primary');

    & > div {
      img {
        object-fit: cover;
      }
    }

    &__info {
      @include space-between;
      align-items: center;
      padding: 10px 15px;

      background-color: map-get($colors, 'action.hover');
    }

    h4 {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }

    p {
      span {
        margin: 0 0 0 8px;
      }
    }
  }
}
