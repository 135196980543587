@import '../../styles/helpers/index.scss';

.header {
  &__inner {
    @include space-between;
    align-items: center;
    padding: 15px 0;
  }
}

.logo {
  font-family: 'Kode Mono', monospace;
  color: map-get($colors, 'text.primary');
  font-size: 32px;
  line-height: 100%;
  text-decoration: none;
  text-align: center;
}

.burger {
  color: map-get($colors, 'text.primary');
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  width: 100%;
  height: inherit;

  background: map-get($colors, 'background.paper');

  transition: left 0.3s ease;

  &--hidden {
    left: -100%;
  }

  &__inner {
    @include align-center;
    flex-direction: column;
    justify-content: center;

    position: relative;
    height: inherit;

    & > *:not(:last-child) {
      margin: 0 0 30px;
    }
  }

  a,
  button {
    display: inline-flex;
    color: map-get($colors, 'text.primary');
    font-weight: bold;
    text-transform: uppercase;

    span {
      margin: 0 0 0 10px;
    }
  }

  a {
    @include align-center;

    font-size: 24px;
    text-decoration: none;
  }

  button {
    font-size: 18px;
  }
}

.close {
  margin: 0 !important;
  position: absolute;
  top: 15px;
  right: 0;
}
