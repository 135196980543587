@import '../../styles/helpers/index.scss';

.footer {
  padding: 10px 0;
  text-align: center;

  a {
    display: block;
    margin: 10px 0 0;
    color: map-get($colors, 'text.disabled');
    text-decoration: none;
    font-size: 12px;

    span {
      display: inline-block;
    }
  }

  span {
    display: block;
    font-size: 12px;
    color: map-get($colors, 'text.disabled');
  }
}
