@import '../../styles/helpers/index.scss';

.my-future-games {
  margin: 30px 0 0;

  h2 {
    margin: 0 0 15px;
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.game-card {
  display: block;
  text-decoration: none;
  color: map-get($colors, 'text.primary');

  div {
    @include align-center;
    justify-content: space-between;

    margin: 5px 0 0;

    div {
      text-transform: uppercase;
      font-weight: 600;

      span {
        margin: 0 0 0 10px;
        color: #ff3d00;
        font-size: 18px;
      }
    }
  }
}
