@use 'scss-reset/reset';

@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&display=swap');

// @import url('https://fonts.googleapis.com/css?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kode+Mono:wght@400..700&family=Tilt+Neon&display=swap');

@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import './helpers/variables.scss';
@import './helpers/mixins.scss';
@import './forms.scss';
// @import './typography.scss';

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background.default');
  color: map-get($colors, 'text.primary');
  font-size: 16px;
  font-weight: 400;
  line-height: 1.24;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Inter', sans-serif;

  overflow: auto;
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  #root {
    position: relative;
    height: inherit;

    display: flex;
    flex-direction: column;

    main {
      flex: 1 0 auto;
    }
  }
}

.section-title {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;

  max-width: 85%;
  margin: 0 auto;

  & > *:not(:last-child) {
    margin: 0 0 15px;
  }
}

.hr {
  width: 100%;
  height: 2px;
  display: block;
  position: relative;
  margin-bottom: 0em;
  margin: 0 0 30px;

  &:after,
  &:before {
    content: '';
    position: absolute;

    width: 100%;
    height: 2px;
    bottom: 50%;
    left: 0;
  }

  &:before {
    background: linear-gradient(90deg, transparent 0%, transparent 50%, transparent 50%, transparent 100%);
    background-position: center;
    z-index: 1;
  }

  &:after {
    transition: opacity 0.3s ease, animation 0.3s ease;

    background: linear-gradient(
      to right,
      #62efab 5%,
      #f2ea7d 15%,
      #f2ea7d 25%,
      #ff8797 35%,
      #ff8797 45%,
      #e1a4f4 55%,
      #e1a4f4 65%,
      #82fff4 75%,
      #82fff4 85%,
      #62efab 95%
    );

    background-size: 200%;
    background-position: 0%;
    animation: bar 15s linear infinite;
  }
}
