@import '../../styles/helpers/index.scss';

.tasks {
}

.task-card {
  padding: 0 0 30px;

  &__title {
    @include align-center;
    justify-content: space-between;

    margin: 0 0 10px;
    font-size: 24px;
    font-weight: 700;
  }

  &__description {
    padding: 0 0 0 5px;
    font-size: 18px;
    line-height: 200%;
  }

  form {
    margin: 20px 0 0;
    max-width: 100%;
  }
}
