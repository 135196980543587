@import '../../../styles/helpers/index.scss';

.login {
  @include align-center;
  justify-content: center;
  flex-direction: column;

  height: 100%;
  padding: 0 15px;

  h1 {
    font-family: 'Kode Mono', monospace;

    font-size: 56px;
    font-weight: bold;
    text-align: center;

    text-transform: uppercase;
  }

  p {
    margin: 30px 0;
    font-size: 24px;
    font-weight: bold;
  }

  a {
    margin: 30px 0 0;
    color: map-get($colors, 'text.primary');
  }
}
